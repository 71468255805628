exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cookies-es-jsx": () => import("./../../../src/pages/cookies.es.jsx" /* webpackChunkName: "component---src-pages-cookies-es-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-index-es-jsx": () => import("./../../../src/pages/index.es.jsx" /* webpackChunkName: "component---src-pages-index-es-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-es-jsx": () => import("./../../../src/pages/legal.es.jsx" /* webpackChunkName: "component---src-pages-legal-es-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-privacy-es-jsx": () => import("./../../../src/pages/privacy.es.jsx" /* webpackChunkName: "component---src-pages-privacy-es-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */)
}

